<script setup lang="ts">
import { computed, type PropType } from "vue";

type Tag = "h1" | "h2" | "h3" | "h4";

type Size = "huge" | "large" | "mediumplus" | "medium" | "small" | "x-small" | "xx-small";

const props = defineProps({
  tag: {
    type: String as PropType<Tag>,
    required: true,
  },
  size: {
    type: String as PropType<Size>,
    default: "medium",
  },
  branded: {
    type: Boolean,
    default: false,
  },
});

const classes = computed(() => {
  switch (props.size) {
    case "huge": return "text-display-large lg:text-display-huge font-medium";
    case "large": return "text-display-mediumplus lg:text-display-large font-medium";
    case "mediumplus": return "text-display-medium lg:text-display-mediumplus font-medium";
    case "medium": return "text-display-medium font-medium";
    case "small": return "text-display-small font-medium";
    case "x-small": return "text-display-x-small font-medium";
    case "xx-small": return "text-display-xx-small font-medium";
  }
});
</script>

<template>
  <div class="flex flex-wrap items-center justify-between gap-4">
    <component :is="tag" :class="[ { 'branded': branded }, classes ]">
      <slot /><span class="dot hidden">.</span>
    </component>
    <div v-if="$slots.right">
      <slot name="right" />
    </div>
  </div>
</template>

<style scoped>
h1, h2, h3, h4 {
  @apply text-ink-contrast;
}
.branded .dot {
  @apply inline-block text-green-90;
}
/*.branded {
  @apply relative pr-6;

  &::before {
    @apply absolute bottom-0 right-0 h-4 w-4 rounded-full bg-green-90;
    content: "";
  }
}*/
</style>
