import { createI18n } from 'vue-i18n'
import { useMainStore } from "@/stores/main";

export default function useI18n() {
  const mainStore = useMainStore();

  return createI18n({
    legacy: false,
    locale: mainStore.getLanguage() ?? 'no',
    //fallbackLocale: 'en',
    messages: {

      en: {

        productNames: {
          'Microsoft_365_Copilot': 'Copilot for Microsoft 365',
          'Dynamics_365_Operations_Application_Partner_Sandbox': 'Dynamics 365 Operations Application Partner Sandbox',
          'Dynamics_365_Sales_Field_Service_and_Customer_Service_Partner_Sandbox': 'Dynamics 365 Sales, Field Service and Customer Service Partner Sandbox',
          'SPE_E5': 'Microsoft 365 E5',
          'POWERAPPS_DEV': 'Microsoft Power Apps for Developer',
          'VIVA': 'Microsoft Viva-series',
          'POWERAPPS_PER_USER': 'Power Apps Premium',
          'POWER_BI_PRO': 'Power BI Pro',
          'Power_Pages_vTrial_for_Makers': 'Power Pages vTrial for Makers',
          'PROJECTPREMIUM': '',//'Project-abonnement 5',
          'STREAM': '',//'Prøveversjon av Microsoft Stream',
          'VISIOCLIENT': '',//'Visio-abonnement 2',
          'CPC_E_8C_32GB_512GB': 'Windows 365 Enterprise 8 vCPU, 32 GB, 512 GB',
          productno_17168227_text: 'For educational institutions that need a Microsoft 365 A3 license for their employees',
          productno_17168227_moreText: 'The product includes an A3 license from Microsoft, full management, a cloud-based printing solution, email signature setup, perpetual backup, and full support for the employee',
          productno_17168226_text: 'For educational institutions that need a free license for their students',
          productno_17168226_moreText: 'The product includes an A3 license for students from Microsoft, full management, a cloud-based printing solution, email signature setup, perpetual backup, and full support for the staff',
          productno_17168197_text: 'For those of your employees who need a Microsoft 365 Frontline license',
          productno_17168197_moreText: 'The product includes a Frontline license from Microsoft, full management, email signature setup, and complete support for the employee',
          productno_17168196_text: 'For those of your employees who need a Microsoft 365 E3 license',
          productno_17168196_moreText: 'The product includes an E3 license from Microsoft, full management, a cloud-based printing solution, email signature setup, perpetual backup, and full support for the employee.',
          productno_17168195_text: 'For those of your employees who need a Microsoft 365 Business Premium license',
          productno_17168195_moreText: 'The product includes a Business Premium license from Microsoft, full management, cloud-based printing solution, email signature setup, perpetual backup, and complete support for the employee',
        },

        assetCategoryNames: {
          '6105e595-86df-e911-a866-000d3ab0dc71': 'Meeting room equipment',
          '9d2ea1e8-980b-ef11-9f89-0022489e5543': 'Projector',
          'e960a319-990b-ef11-9f89-0022489e5543': 'Audio',
          '469fc82d-990b-ef11-9f89-0022489e5543': 'Booking panel',
          '4e2a7634-990b-ef11-9f89-0022489e5543': 'Screen',
          'a48a3999-aca5-ef11-b8e9-7c1e52369000': 'Connection',
          'c38a3999-aca5-ef11-b8e9-7c1e52369000': 'Digital Signage Screen',
          'bf19399f-aca5-ef11-b8e9-7c1e52369000': 'Control panel',
          'c765ada5-aca5-ef11-b8e9-7c1e52369000': 'Lights',
          '1566ada5-aca5-ef11-b8e9-7c1e52369000': 'PC',
          'b205b4ab-aca5-ef11-b8e9-7c1e52369000': 'Monitor (Desktop)',
          '1f1ebbb1-aca5-ef11-b8e9-7c1e52369000': 'Computer Peripherals',
          '651ebbb1-aca5-ef11-b8e9-7c1e52369000': 'Mobile phone',
          '26d1b3b7-aca5-ef11-b8e9-7c1e52369000': 'Access Point',
          'fd89babd-aca5-ef11-b8e9-7c1e52369000': 'Switch',
          '6b8ababd-aca5-ef11-b8e9-7c1e52369000': 'Firewall',
          '15c34b18-b5a5-ef11-b8e9-7c1e52369000': 'Printer',
        },

        assets: {
          singular: 'asset', //'enhet',
          noRegistered: 'No registered assets.', //'Ingen registrerte enheter.',
          productImage: 'Product image', //'Produktbilde',
          usedBy: 'Used by', //'Brukes av',
          change: 'Change', //'Endre',
          about: 'About this assets', //'Om denne enheten',
          serial: 'Serial number', //'Serienr',
          status: 'Service status', //'Servicestatus',
          info: 'Product information', //'Produktinformasjon',
          operationalStatus: 'Operational status', //'Operasjonell status',
          purchaseDate: 'Purchase date', //'Kjøpsdato',
          warranty: 'Warranty', //'Garanti',
          lastSignin: 'Last signin', //'Sist innlogget',
          intune: 'Connected to InTune', //'Koblet til InTune',
          managed: 'Managed', //'Administrert',
          azure: 'Registered in AD', //'Registrert i AD',
          specs: 'Specification', //'Spesifikasjoner',
          os: 'Operating system', //'Operativsystem',
          ver: 'Version', //'Versjon',
          manufacturer: 'Manufacturer', //'Produsent',
          model: 'Model', //'Modell'
        },

        finance: {
          name: 'Finance', //'Økonomi',
          ivoiceHistory: 'Invoice history', //'Fakturahistorikk',
          noIvoiceHistory: 'No invoice history.', //'Ingen fakturahistorikk funnet.',
          referenceOrder: 'Reference {id}', //'Kreditnota {id}',
          orderPaid: 'Paid {date}', //'Betalt {date}'
        },

        incidents: {
          name: 'Help desk', //'Help desk',
          singular: 'case', //'sak',
          needHelpWithAsset: 'Need help with asset', //'Bestill support',
          needHelpWithService: 'Need help with service', //'Meld skade',
          updateUser: 'Update user', //'Kjøp av produkt, tjeneste eller lisens',
          send: 'Send inquiry', //'Send henvendelse',
          sent: 'Your inquiry was sent', //'Din henvendelse er sendt',
          your: 'Your cases', //'Dine saker',
          new: 'New case', //'Ny sak',
          createNew: 'Create new case', //'Opprett ny sak',
          selectCategory: 'Select category', //'Velg kategori',
          searchOptional: 'Leave empty if you can\'t find this information', //'Finner du ikke denne informasjonen? La denne stå tom.',
          searchAssetsContactsLocations: 'Search for equipment, employees or meeting rooms',
          searchAssets: 'Search for equipment',
          describeProblem: 'Describe problem', //'Beskriv problemet',
          noRegistered: 'No cases found.', //'Ingen saker funnet.',
          ticketNr: 'Ticket ID', //'Saksnr',
          serviceAndSupport: 'Customer service and technical support', //'Kundeservice og teknisk support',
          yourContact: 'Your Bravo contact (KAM)', //'Din Bravo kontakt (KAM)',
          open: 'No open cases | {n} open case | {n} open cases', //'Ingen åpne saker | {n} åpen sak | {n} åpne saker',
          history: 'Support history', //'Sakshistorikk',
          noHistoryRegistered: 'No cases registered for this asset.', //'Ingen sakshistorikk for denne enheten.',
          subject: 'Subject', //'Emnefelt',
          createdOn: 'Created on', //'Meldt inn',
          createdBy: 'Created by', //'Sendt inn av',
          messageHistory: 'Message history', //'Meldingshistorikk',
          noMessageHistory: 'No messages for this case.', //'Ingen meldinger i denne saken.',
          answer: 'Write answer...', //'Skriv svar...'
          cancel: 'Cancel case', //'Kanseller sak'
          cancelSure: 'Are you sure?', //'Er du sikker?',
          cancelWhy: 'Tell us why the case no longer should be handled', //'Fortell oss gjerne hvorfor saken ikke lenger skal behandles',
          cancelConfirm: 'Confirm', //'Bekreft kansellering'
          messagesClosed: 'The case is {state} and new messages are not available. Report a new case if you need support.',
          connectCaseToEmployee: 'Relate case to employee',
          persistedProblem: 'This is an error that has persisted for a long time',
          selectEmployee: 'Select employee',
          onBehalfOf: 'on behalf of',
          channel: 'Channel',
          merged: '{count} case have been merged with this case | {count} cases have been merged with this case',
          mergedThis: 'This case has been merged with',
          seeMerged: 'See cases'
        },

        services: {
          name: 'Bravo-services', //'Tjenester',
          service: 'Service', //'Tjeneste',
          overview: 'Overview of your services', //'Se oversikt over dine tjenester',
        },

        settings: {
          name: 'Settings', //'Innstillinger',
          text: 'Employee must receive all emails about invoices', //'Ansatt skal motta alle eposter om faktura'
          user: 'user', //'bruker'
          users: 'Users', //'Brukere'
          noUsersRegistered: 'No users registered.',
          role: 'Role',
          editAxs: 'Change access',
          admin: 'Administrator',
          manager: 'Manager',
          employee: 'Employee',
          noaxs: 'No portal access',
          updated: 'User is updated',
          adminTitle: 'Admin Access',
          adminText: 'An administrator can manage the main account and all sub-accounts. The user can also view data on employees, meeting rooms, equipment, licenses, services, and support cases linked to the account, as well as handle invoices and agreements. The administrator can register support cases, onboard and offboard employees, order equipment, modify access rights for employees, and adjust both personal and company account settings.',
          managerTitle: 'Manager Access',
          managerText: 'Manager access authorizes the user to place orders on behalf of the company\'s account.The user can also view information about employees, meeting rooms, equipment, licenses, services, and support cases related to the account.Furthermore, the user can register support cases for others, add or remove employees, and order equipment for the entire company.The user can also modify their personal profile.',
          employeeTitle: 'Employee Access',
          employeeText: 'Employee access only allows the user to view equipment and licenses that are directly linked to their user account. The user can only register and view their own support cases. Additionally, the user can modify their personal profile.',
          noaxsTitle: 'No portal access',
          noaxsText: 'The user have no access to view information or send any inquiries from the portal account.',
        },

        agreements: {
          name: 'Agreements', //'Avtaler',
          singular: 'agreement', //'avtale',
          your: 'Your agreements', //'Dine avtaler',
          number: 'Number of agreements', //'Antall avtaler',
          noRegistered: 'No registered agreements.', //'Ingen registrerte avtaler.',
          overview: 'See an overview on your agreements.', //'Se oversikt over dine avtaler',
        },

        locations: {
          type: 'Type', //'Type',
          singular: 'meeting room', //'møterom',
          meetingRoom: 'Meeting rooms', //'Møterom',
          //your: 'Your meeting rooms', //'Dine møterom',
          overview: 'Overview of your meeting rooms', //'Se oversikt over møterom',
          unregister: 'Disconnect from meeting room', //'Avregistrer for møterom',
          new: 'Register new meeting room', //'Registrer nytt møterom',
          noRegistered: 'No registered meeting rooms', //'Det er ikke registrert noen møterom',
          equipmentCount: 'Equipment count', //'Antall utstyr',
          size: 'Size', //'Størrelse',
          serviceAgreement: 'Service agreement', //'Serviceavtale',
          selectLocation: 'Select location',
          selectDepartment: 'Select department',
        },

        licenses: {
          name: 'Licenses', //'Lisenser',
          singular: 'license', //'lisens',
          //select: 'Select licenses', //'Velg lisenser',
          info: 'License information', //'Lisensinformasjon',
          edit: 'Edit licenses', //'Rediger lisens',
          number: 'Number of licenses', //'Antall lisenser',
          available: 'Available licenses', //'Ledige lisenser',
          noRegisteredFor: 'No registered licenses for {name}', //'Ingen registrerte lisenser på {name}',
          other: 'Other licenses', //'Andre lisenser',
          noRegisteredOther: 'No other registered licenses.', //'Ingen andre registrerte lisenser.',
          microsoft: 'Microsoft licenses', //'Microsoft lisenser',
          noRegisteredMicrosoft: 'You have no Microsoft licenses registered with Bravo.', //'Ingen registrerte Microsoft-lisenser.',
        },

        equipment: {
          name: 'Equipment', //'Utstyr',
          singular: 'equipment', //'utstyr',
          equipmentName: 'Equipment name', //'Utstyrsnavn',
          select: 'Select equipment', //'Velg utstyr',
          noRegisteredFor: 'No equipment registered for {name}', //'Det er ikke registrert noe utstyr på {name}',
          belongs: 'Belongs to', //'Tilhører'
          ofEmployee: 'Employee equipment', //'Ansattutstyr',
          ofMeetingRoom: 'Meeting room equipment', //'Møteromsutstyr',
          ofNetwork: 'Peripheral devices', //'Nettverksutstyr'
        },

        employees: {
          name: 'Employees', //'Ansatte',
          singular: 'employee', //'ansatt',
          unregisterUser: 'Unregister for user', //'Avregistrer for bruker',
          register: 'Add new employee', //'Registrer ny ansatt',
          info: 'Employee information', //'Ansattinformasjon',
          your: 'Your employees', //'Dine ansatte',
          noRegistered: 'No registered employees.', //'Ingen registrerte ansatte.',
          unregister: 'Offboard employee', //'Avregistrer ansatt',
          overview: 'View employee directory', //'Se oversikt over ansatte',
          reportingManager: 'Reporting manager',
          searchManager: 'Select or search for manager', //'Velg eller søk på nærmeste leder'
          searchManagerHint: 'Contact Bravo if you can\'t find the manager',
          jobDetails: 'Details',
          startDate: 'Employment start date',
          jobTitle: 'Job title',
          createEmail: 'Create employee email',
          //emailPreview: 'Email preview',
          editEmail: 'Edit email',
          manageLogin: 'Manage login credentials',
          manageLoginInfo: 'We will send the login credentials to you {email}, so you can share with the new employee when onboarding',
          manageLoginQuestion: 'I prefer sending the login credentials to another email.',
          manageLoginEmail: 'Enter email',
          selectLicenses: 'License bundle | Choose the suitable license bundle',
          selectLicensesInfo: 'License available in the agreement | Licenses available in the agreement',
          additionalOptions: 'Additional options',
          availableLicenses: 'Licenses available in the agreement',
          otherLicenses: 'For example, Adobe Creative Cloud, other',
          requestEquipment: 'Request equipment',
          requestEquipmentText: 'Let us know what equipment your new hire needs, and we\'ll send you a quote with exact specs and pricing.You can accept, adjust, or decline – no strings attached.',
          requestEquipmentInfo: 'Enter the requested equipment here, and we\'ll send you a quote.',
          requestEquipmentContact: 'An account manager will contact you after your request is submitted.',
          submitRegister: 'Add employee',
          confirm: 'Confirm',
          registerSummary: 'Summary',
          registerConfirmation: 'Confirmation',
          whatHappens: 'What happens next',
          whatHappensText: 'The new employee\'s user account will be created as soon as we receive the order, and login information will be sent to the specified email address. When the new employee logs onto their computer for the first time, it will automatically be set up according to the company\'s configuration, making it ready for use without any manual setup.',
          loginInfoSentTo: 'Login credentials will be sent to',
          employeeEmail: 'Employee email',
          orderedLicenses: 'Licenses ordered',
          orderedEquipment: 'Equipment ordered',
          aboutEquipment: 'About equipment',
          equipmentContact: 'An account manager will contact you after your request is submitted.',
          yourMessage: 'Your message',
          done: 'Done',
          removeDate: 'Date',
          removeDateInfo: 'The account will be deactivated after the end date, and then fully terminated three weeks later. If you have specific requests in connection with this, you can contact our support department at support{\'@\'}bravosteps.no.',
          removeDateHint: 'Tip!',
          removeDateHintText: 'If there is more than a month until the employee is due to leave, we recommend filling in the form a couple of weeks before, in case of any changes that may occur.',
          removeEmail: 'Email',
          removeEmailShouldAutoReply: 'Should the email include an automatic reply after the employee has left?',
          removeEmailAutoReplyInfo: 'Auto reply will be enabled three weeks after employee end date',
          removeEmailAutoReply: 'Auto-reply message',
          removeEmailAutoReplyExample: 'Example',
          removeEmailAutoReplyTemplate: 'Hei!\r\nJeg har avsluttet mitt arbeidsforhold hos {company}.\r\nFor fremtidige henvendelser, ta kontakt med [Stilling] [Navn] på [Epost] eller [Telefon]\r\n\r\nVennlig hilsen,\r\n{name}',
          removeEmailAutoReplyPreviev: 'Preview',
          equipmentToHandle: 'Equipment',
          eqipmentToEmployee: 'Reallocate to an employee',
          equipmentHandling: 'What?',
          equipmentHandling1: 'Retain for now',
          equipmentHandling2: 'Reset and reallocate to an employee',
          equipmentHandling3: 'Return to Bravo',
          equipmentHandlingInfo1: 'The equipment will be retained by the company for now and will be reset at 2:00 PM on the employee\'s end date. If you have specific requests regarding this, please contact Bravo customer support at <a href="mailto:support{\'@\'}bravosteps.no">support{\'@\'}bravosteps.no</a>',
          equipmentHandlingInfo2: 'When the current owner\'s end date is reached, the equipment will be reset and transferred to the selected employee. We reset the equipment at 2:00 PM on the end date. If you have specific requests regarding this, please contact Bravo customer support at <a href="mailto:support{\'@\'}bravosteps.no">support{\'@\'}bravosteps.no</a>',
          equipmentHandlingInfo3: 'If you lease equipment from Bravo under a DaaS agreement, the equipment can be returned to us. We will contact you regarding the return of the equipment.',
          equipmentDate: 'Choose the date you want to reset the equipment',
          unregisterSummary: 'Summary',
          unregisterConfirmation: 'Confirmation',
          caseSubmitted: 'Your request has been submitted to Bravo and a case has been created in the help desk.',
          unregisterText: 'Bravo will receive and process the offboarding. You will receive an email once it has been handled. After the end date, the employee will no longer have access to their email, Teams, or OneDrive, and will not be able to log in to their work computer. An out-of-office reply will function for three weeks after the end date, after which the account will be fully terminated.',
          seeCase: 'See request in the help desk',
          infoAbout: 'Information about',
          endDate: 'End date',
          equipmentHandled: 'Equipment handled',
          infoAboutLicense: 'Info about license',
          infoLicenseTerminated: 'Licenses associated with the employee are terminated by Bravo in accordance with the agreement',
          formSubmitted: 'Form submitted',
          noBundle: 'No license package agreement found',
          orderContact: 'Want to order a license package? Contact your account manager at <a href="mailto:{email}">{email}</a>',
          orderInfo: 'Bravo offers tailored license packages, including management, operations, security and IT support – all in one place.',
          orderMoreInfo: 'Learn more at <a href="https://www.bravosteps.no" target="_blank">www.bravosteps.no</a>',
          comment: 'Comment',
          commentExample: 'I want...'
        },

        feedback: {
          name: 'Feedback', //'Tilbakemelding',
          give: 'Give feedback', //'Gi tilbakemelding',
          question: 'What would you rate the overall user experience of the portal?', //'Hva synes du om brukeropplevelsen i Bravo portalen?',
          noGood: 'Not good', //'Ikke så bra',
          good: 'Very good ', //'Veldig bra',
          getGood: 'What can we do to improve?', //'Hva kan vi gjøre bedre?',
          placeholder: '', //'Vi ønsker ærlige og konstruktive tilbakemeldinger så vi kan gjøre brukeropplevelsen bedre for deg',
          email: 'Leave you email here if we can contact you for more feedback', //'Legg igjen epost om du vil snakke med oss',
          send: 'Send feedback', //'Send tilbakemelding',
          thanks: 'We appreciate your feedback', //'Vi setter pris på din tilbakemelding',
          outro: 'Your feedback is utilized in the ongoing development of the portal to ensure the best possible user experience for you', //'Din tilbakemelding brukes i videre utvikling av portalen, for å gjøre brukeropplevelsen best mulig for deg.'
        },

        files: {
          add: 'Add files',
          drop: 'Upload or drop files here',
          dropHere: 'Drop files here',
          limit: 'jpeg or png, max 4 MB'
        },

        general: {
          yes: 'Yes',
          no: 'No',
          account: 'Account', //'Konto',
          noAccount: 'Invalid user', //'Ugyldig bruker',
          login: 'Log in', //'Login',
          logout: 'Log out', //'Logg ut',
          details: 'Details', //'Detaljer',
          category: 'Category', //'Kategori',
          inventory: 'Inventory', //'Inventar',
          expires: 'Expires', //'Utløper',
          expirationDate: 'Expiration date', //'Utløpsdato',
          delete: 'Delete', //'Slett',
          notImplemented: 'Not implemented', //'Ikke implementert',
          notImplementedIncoming: 'This feature is not implemented yet. We are working on it.', //'Funksjonen er ikke implementert, men kommer.',
          coming: 'Incoming!', //'Kommer!',
          newComming: 'New feature coming soon!',
          newCommingEquipment: 'Soon, you\'ll be able to select and order equipment directly from this page. Stay tuned!',
          goTo: 'Go to', //'Gå til',
          goBack: 'Go back', //'Gå tilbake',
          mandatory: 'Mandatory', //'Obligatorisk',
          notMandatory: 'Not mandatory', //'Ikke obligatorisk',
          firstname: 'First name', //'Fornavn',
          lastname: 'Last name', //'Etternavn',
          email: 'Email', //'Epost',
          emailPrivate: 'Private email',
          mobile: 'Mobile',
          phone: 'Phone', //'Telefonnummer',
          location: 'Location', //'Lokasjon',
          city: 'City', //'Sted',
          address: 'Address',
          department: 'Department', //'Avdeling',
          nearestManager: 'Nearest manager', //'Nærmeste leder',
          next: 'Next', //'Neste',
          save: 'Save', //'Lagre',
          search: 'Search', //'Søk',
          name: 'Name', //'Navn',
          job: 'Job title', //'Stilling',
          status: 'Status', //'Status',
          noStatus: 'No status', //'Ingen status',
          complianceStatus: 'Compliance status', //'Sikkerhetsstatus',
          compliant: 'Compliant', //'Compliant',
          notCompliant: 'Not compliant', //'Not compliant',
          unknown: 'Unknown', //'Ukjent',
          date: 'Date', //'Dato',
          order: 'Order', //'Ordre',
          sum: 'Sum', //'Sum',
          other: 'Other', //'Annet',
          errorTryAgain: 'Something went wrong, please try again', //'Noe gikk feil. Prøv igjen',
          title: 'Title', //'Tittel',
          open: 'Open', //'Åpen',
          //active: 'Active', //'Aktiv',
          closed: 'Closed', //'Lukket',
          cancelled: 'Cancelled', //'Kansellert',
          //solved: 'Solved', //'Løst',
          topic: 'Topic', //'Emne',
          contact: 'Contact', //'Kontakt',
          floor: 'Floor', //'Etasje',
          bravoCustomerService: 'Bravo customer service', //'Bravo kundeservice',
          send: 'Send', //'Send',
          readMore: 'Read more', //'Les mer',
          readLess: 'Read less', //'Les mindre',
          filter: 'Filter', //'Filter',
          reset: 'Reset', //'Nullstill',
          applyFilter: 'Apply filter', //'Bruk filter',
          sort: 'Sort', //'Sorter',
          language_en: 'English',
          language_no: 'Norsk',
          download: 'Download',
          employee: 'Employee',
          meetingRoom: 'Meeting room',
          equipment: 'Equipment',
          noHits: 'No hits',
          all: 'All',
          domain: 'Domain',
          error_invalidFormat: 'Invalid format',
          selected: 'Selected',
          clearForm: 'Clear form'
        },

        incidentStatus: {
          inProgress: 'In progress',
          onHold: 'On hold',
          respondedByCustomer: 'In progress',
          waitingForCustomer: 'New message',
          problemSolved: 'Closed',
          cancelled: 'Cancelled',
          informationProvided: 'In progress',
          merged: 'Closed',
          waitingFor3rdParty: 'On hold',
          new: 'New case',
          createdWorkOrder: 'Assigned to technician',
          scheduled: 'Scheduled service'
        },

        incidentOrigin: {
          phone: 'Phone',
          email: 'Email',
          assignment: 'Assignment',
          portal: 'Portal',
        }
      },


      no: {

        productNames: {
          'Microsoft_365_Copilot': 'Copilot for Microsoft 365',
          'Dynamics_365_Operations_Application_Partner_Sandbox': 'Dynamics 365 Operations Application Partner Sandbox',
          'Dynamics_365_Sales_Field_Service_and_Customer_Service_Partner_Sandbox': 'Dynamics 365 Sales, Field Service and Customer Service Partner Sandbox',
          'SPE_E5': 'Microsoft 365 E5',
          'POWERAPPS_DEV': 'Microsoft Power Apps for Developer',
          'VIVA': 'Microsoft Viva-serien',
          'POWERAPPS_PER_USER': 'Power Apps Premium',
          'POWER_BI_PRO': 'Power BI Pro',
          'Power_Pages_vTrial_for_Makers': 'Power Pages vTrial for Makers',
          'PROJECTPREMIUM': 'Project-abonnement 5',
          'STREAM': 'Prøveversjon av Microsoft Stream',
          'VISIOCLIENT': 'Visio-abonnement 2',
          'CPC_E_8C_32GB_512GB': 'Windows 365 Enterprise 8 vCPU, 32 GB, 512 GB',
          productno_17168227_text: 'For utdanningsinstitusjoner som trenger Microsoft 365 A3-lisens for sine ansatte',
          productno_17168227_moreText: 'Pakken inkluderer en A3-lisens fra Microsoft, full drift, skybasert printerløsning, oppsett av e-postsignatur, evig backup og full support for den ansatte',
          productno_17168226_text: 'For utdanningsinstitusjoner som trenger en gratislisens til sine studenter',
          productno_17168226_moreText: 'Pakken inneholder en A3 lisens for studenter fra Microsoft, full drift, skybasert printerløsning, oppsett av e-postsignatur, evig backup og full support for den ansatte',
          productno_17168197_text: 'For de av dine ansatte som trenger en Microsoft 365 Frontline-lisens',
          productno_17168197_moreText: 'Pakken inkluderer Frontline-lisens fra Microsoft, full drift, oppsett av e-postsignatur og full support for den ansatte',
          productno_17168196_text: 'For de av dine ansatte som trenger en Microsoft 365 E3-lisens',
          productno_17168196_moreText: 'Pakken inkluderer E3-lisens fra Microsoft, full drift, skybasert printerløsning, oppsett av e-postsignatur, evig backup og full support for den ansatte',
          productno_17168195_text: 'For de av dine ansatte som trenger Microsoft 365 Business Premium-lisens',
          productno_17168195_moreText: 'Pakken inkluderer Business Premium-lisens fra Microsoft, full drift, skybasert printerløsning, oppsett av epostsignatur, evig backup og full support for den ansatte',
        },

        assetCategoryNames: {
          '6105e595-86df-e911-a866-000d3ab0dc71': 'Møteromsutstyr',
          '9d2ea1e8-980b-ef11-9f89-0022489e5543': 'Projektor',
          'e960a319-990b-ef11-9f89-0022489e5543': 'Lyd',
          '469fc82d-990b-ef11-9f89-0022489e5543': 'Bookingpanel',
          '4e2a7634-990b-ef11-9f89-0022489e5543': 'Skjerm',
          'a48a3999-aca5-ef11-b8e9-7c1e52369000': 'Tilkobling',
          'c38a3999-aca5-ef11-b8e9-7c1e52369000': 'Digital signage screen',
          'bf19399f-aca5-ef11-b8e9-7c1e52369000': 'Styringpanel',
          'c765ada5-aca5-ef11-b8e9-7c1e52369000': 'Lys',
          '1566ada5-aca5-ef11-b8e9-7c1e52369000': 'PC',
          'b205b4ab-aca5-ef11-b8e9-7c1e52369000': 'Skjerm (monitor)',
          '1f1ebbb1-aca5-ef11-b8e9-7c1e52369000': 'Tastatur og mus',
          '651ebbb1-aca5-ef11-b8e9-7c1e52369000': 'Mobiltelefon',
          '26d1b3b7-aca5-ef11-b8e9-7c1e52369000': 'Aksesspunkt',
          'fd89babd-aca5-ef11-b8e9-7c1e52369000': 'Switch',
          '6b8ababd-aca5-ef11-b8e9-7c1e52369000': 'Brannmur',
          '15c34b18-b5a5-ef11-b8e9-7c1e52369000': 'Printer',
        },

        assets: {
          singular: 'enhet',
          noRegistered: 'Ingen registrerte enheter.',
          productImage: 'Produktbilde',
          usedBy: 'Brukes av',
          change: 'Endre',
          about: 'Om denne enheten',
          serial: 'Serienr',
          status: 'Servicestatus',
          info: 'Produktinformasjon',
          operationalStatus: 'Operasjonell status',
          purchaseDate: 'Kjøpsdato',
          warranty: 'Garanti',
          lastSignin: 'Sist innlogget',
          intune: 'Koblet til InTune',
          managed: 'Administrert',
          azure: 'Registrert i AD',
          specs: 'Spesifikasjoner',
          os: 'Operativsystem',
          ver: 'Versjon',
          manufacturer: 'Produsent',
          model: 'Modell'
        },

        finance: {
          name: 'Økonomi',
          ivoiceHistory: 'Fakturahistorikk',
          noIvoiceHistory: 'Ingen fakturahistorikk funnet.',
          referenceOrder: 'Kreditnota {id}',
          orderPaid: 'Betalt {date}'
        },

        incidents: {
          name: 'Help desk',
          singular: 'sak',
          needHelpWithAsset: 'Trenger hjelp med utstyr',
          needHelpWithService: 'Trenger hjelp med tjeneste',
          updateUser: 'Endring på bruker',
          send: 'Send henvendelse',
          sent: 'Din henvendelse er sendt',
          your: 'Dine saker',
          new: 'Ny sak',
          createNew: 'Opprett ny sak',
          selectCategory: 'Velg kategori',
          searchOptional: 'Finner du ikke denne informasjonen? La denne stå tom.',
          searchAssetsContactsLocations: 'Søk på utstyr, ansatt eller møterom',
          searchAssets: 'Søk på utstyr',
          describeProblem: 'Beskriv problemet',
          noRegistered: 'Ingen saker funnet.',
          ticketNr: 'Saksnr',
          serviceAndSupport: 'Kundeservice og teknisk support',
          yourContact: 'Din Bravo kontakt (KAM)',
          open: 'Ingen åpne saker | {n} åpen sak | {n} åpne saker',
          history: 'Sakshistorikk',
          noHistoryRegistered: 'Ingen sakshistorikk for denne enheten.',
          subject: 'Emnefelt',
          createdOn: 'Meldt inn',
          createdBy: 'Sendt inn av',
          messageHistory: 'Meldingshistorikk',
          noMessageHistory: 'Ingen meldinger i denne saken.',
          answer: 'Skriv svar...',
          cancel: 'Kanseller sak',
          cancelSure: 'Er du sikker?',
          cancelWhy: 'Fortell oss gjerne hvorfor saken ikke lenger skal behandles',
          cancelConfirm: 'Bekreft kansellering',
          messagesClosed: 'Saken er {state} så det kan ikke sendes nye meldinger på denne. Har du fortsatt behov for hjelp, vennligst opprett ny sak.',
          connectCaseToEmployee: 'Knytt sak til ansatt',
          persistedProblem: 'Dette er en feil som har vedvart over lengre tid',
          selectEmployee: 'Velg ansatt',
          onBehalfOf: 'på vegne av',
          channel: 'Kanal',
          merged: '{count} sak har blitt slått sammen med denne saken | {count} saker har blitt slått sammen med denne saken',
          mergedThis: 'Denne saken er slått sammen med',
          seeMerged: 'Se saker'
        },

        services: {
          name: 'Bravo-tjenester',
          service: 'Tjeneste',
          overview: 'Oversikt over tjenester',
        },

        settings: {
          name: 'Innstillinger',
          text: 'Ansatt skal motta alle eposter om faktura',
          user: 'bruker',
          users: 'Brukere',
          noUsersRegistered: 'Ingen brukere funnet.',
          role: 'Rolle',
          editAxs: 'Endre tilgang',
          admin: 'Administrator',
          manager: 'Leder',
          employee: 'Ansatt',
          noaxs: 'Ingen portaltilgang',
          updated: 'Bruker er oppdatert',
          adminTitle: 'Admintilgang',
          adminText: 'En administrator kan administrere hovedkontoen og alle underkontoer. Brukeren kan også se data på ansatte, møterom, utstyr, lisenser, tjenester og supportsaker knyttet til kontoen, samt håndtere fakturaer og avtaler. Administratoren kan registrere supportsaker, registrere og avregistrere ansatte, bestille utstyr, endre tilganger på ansatte og justere sine egne og bedriftens kontoinnstillinger.',
          managerTitle: 'Ledertilgang',
          managerText: 'Ledertilgang gir autorisasjon til å bestille på vegne av kontoen til bedriften. Brukeren kan også se informasjon om ansatte, møterom, utstyr, lisenser, tjenester og supportsaker som er knyttet til kontoen. I tillegg kan brukeren registrere supportsaker for andre, legge til eller fjerne ansatte, og bestille utstyr for hele bedriften. Brukeren kan også endre sin egen personlige profil.',
          employeeTitle: 'Ansattilgang',
          employeeText: 'Ansattilgang gir kun mulighet til å se utstyr og lisenser som er knyttet direkte til brukeren. Brukeren kan bare registrere og se sine egne supportsaker. I tillegg kan brukeren endre sin egen personlige profil.',
          noaxsTitle: 'Ingen portaltilgang',
          noaxsText: 'Brukeren har ingen tilgang til å se informasjon eller sende henvendelser fra portalkontoen.',
        },

        agreements: {
          name: 'Avtaler',
          singular: 'avtale',
          your: 'Dine avtaler',
          number: 'Antall avtaler',
          noRegistered: 'Ingen registrerte avtaler.',
          overview: 'Se oversikt over dine avtaler',
        },

        locations: {
          type: 'Type',
          singular: 'møterom',
          meetingRoom: 'Møterom',
          //your: 'Dine møterom',
          overview: 'Oversikt over møterom',
          unregister: 'Avregistrer for møterom',
          new: 'Registrer nytt møterom',
          noRegistered: 'Det er ikke registrert noen møterom',
          equipmentCount: 'Antall utstyr',
          size: 'Størrelse',
          serviceAgreement: 'Serviceavtale',
          selectLocation: 'Velg lokasjon',
          selectDepartment: 'Velg avdeling',
        },

        licenses: {
          name: 'Lisenser',
          singular: 'lisens',
          //select: 'Velg lisenser',
          info: 'Lisensinformasjon',
          edit: 'Rediger lisens',
          number: 'Antall lisenser',
          available: 'Ledige lisenser',
          noRegisteredFor: 'Ingen registrerte lisenser på {name}',
          other: 'Andre lisenser',
          noRegisteredOther: 'Ingen andre registrerte lisenser.',
          microsoft: 'Microsoft lisenser',
          noRegisteredMicrosoft: 'Du har ingen Microsoft-lisenser registrerte hos Bravo.',
        },

        equipment: {
          name: 'Utstyr',
          singular: 'utstyr',
          equipmentName: 'Utstyrsnavn',
          select: 'Velg utstyr',
          noRegisteredFor: 'Det er ikke registrert noe utstyr på {name}',
          belongs: 'Tilhører',
          ofEmployee: 'Ansattutstyr',
          ofMeetingRoom: 'Møteromsutstyr',
          ofNetwork: 'Eksterne enheter',
        },

        employees: {
          name: 'Ansatte',
          singular: 'ansatt',
          unregisterUser: 'Avregistrer for bruker',
          register: 'Registrer nyansatt',
          info: 'Ansattinformasjon',
          your: 'Dine ansatte',
          noRegistered: 'Ingen registrerte ansatte.',
          unregister: 'Avregistrer ansatt',
          overview: 'Oversikt over ansatte',
          reportingManager: 'Nærmeste leder',
          searchManager: 'Velg eller søk på nærmeste leder',
          searchManagerHint: 'Kontakt Bravo om du ikke finner nærmeste leder',
          jobDetails: 'Ansattforhold',
          startDate: 'Oppstartsdato',
          jobTitle: 'Jobbtittel',
          createEmail: 'Opprett ansatt-epost',
          //emailPreview: 'Forhåndsvisning av epost',
          editEmail: 'Rediger navn i epost',
          manageLogin: 'Håndtering av innloggingsinformasjon',
          manageLoginInfo: 'Vi sender innloggingsinformasjonen til deg på {email}, slik at du kan dele med den nyansatte ved oppstart',
          manageLoginQuestion: 'Jeg ønsker at innloggingsinformasjonen sendes til en annen e-post.',
          manageLoginEmail: 'Oppgi epost',
          selectLicenses: 'Pakke | Velg pakke',
          selectLicensesInfo: 'Lisens tilgjengelig i avtale | Lisenser tilgjengelig i avtale',
          additionalOptions: 'Tilleggsvalg',
          availableLicenses: 'Lisenser tilgjengelig i avtale',
          otherLicenses: 'Feks Adobe Creative Cloud, annet',
          requestEquipment: 'Bestill utstyr',
          requestEquipmentText: 'Beskriv hvilket utstyr den nyansatte trenger, så sender vi deg et tilbud med priser og spesifikasjoner. Du kan da godta, justere eller avslå tilbudet – helt opp til deg.',
          requestEquipmentInfo: 'Fyll inn ønsket utstyr her, så sender vi deg et tilbud.',
          requestEquipmentContact: 'Du vil bli kontaktet av en kundeansvarlig etter at forespørselen er sendt.',
          submitRegister: 'Registrer ansatt',
          confirm: 'Bekreft',
          registerSummary: 'Oppsummering',
          registerConfirmation: 'Bekreftelse',
          whatHappens: 'Dette skjer videre',
          whatHappensText: 'Brukerkontoen til den nyansatte vil bli opprettet så fort vi mottar bestillingen, og påloggingsinformasjon vil bli sendt til spesifisert epostadresse. Når den nyansatte logger på PC-en sin for første gang vil PC-en automatisk settes opp i henhold til firmaets oppsett, slik at den er klar til bruk uten manuell konfigurasjon.',
          loginInfoSentTo: 'Innloggingsinformasjon blir sent til',
          employeeEmail: 'Ansatt epost',
          orderedLicenses: 'Lisens bestilt',
          orderedEquipment: 'Utstyr bestilt',
          aboutEquipment: 'Hendvendelse om utstyr',
          equipmentContact: 'Du vil bli kontaktet av en kundeansvarlig etter at forespørselen er sendt.',
          yourMessage: 'Din melding',
          done: 'Ferdig',
          removeDate: 'Sluttdato',
          removeDateInfo: 'Kontoen vil bli deaktivert etter sluttdatoen, og deretter fullstendig terminert tre uker senere. Dersom du har spesifikke forespørsler i forbindelse med dette, kan du kontakte vår supportavdeling på support{\'@\'}bravosteps.no.',
          removeDateHint: 'Tips!',
          removeDateHintText: 'Om det er over en måned til den ansatte skal slutte anbefaler vi å fylle skjemaet et par uker før, i tilfelle eventuelle endringer som måtte oppstå.',
          removeEmail: 'Eposthåndtering',
          removeEmailShouldAutoReply: 'Skal eposten inneholde automatisk svar etter ansatte har sluttet?',
          removeEmailAutoReplyInfo: 'Autosvar sendes ut i tre uker etter den ansatte har sluttet',
          removeEmailAutoReply: 'Autosvar',
          removeEmailAutoReplyExample: 'Forslag til autosvar',
          removeEmailAutoReplyTemplate: 'Hei!\r\nJeg har avsluttet mitt arbeidsforhold hos {company}.\r\nFor fremtidige henvendelser, ta kontakt med [Stilling] [Navn] på [Epost] eller [Telefon]\r\n\r\nVennlig hilsen,\r\n{name}',
          removeEmailAutoReplyPreviev: 'Forhåndsvisning av autosvar',
          equipmentToHandle: 'Utstyr som må håndteres',
          eqipmentToEmployee: 'Realloker til en ansatt',
          equipmentHandling: 'Hva vil du gjøre med denne?',
          equipmentHandling1: 'Behold inntil videre',
          equipmentHandling2: 'Tilbakestill og realloker til ansatt',
          equipmentHandling3: 'Levere inn til Bravo',
          equipmentHandlingInfo1: 'Utstyret beholdes av selskapet inntil videre, og vil bli tilbakestilt kl. 14:00 på den ansattes sluttdato. Dersom du har spesifikke forespørsler rundt dette, ta kontakt med Bravo kundesupport på <a href="mailto:support{\'@\'}bravosteps.no">support{\'@\'}bravosteps.no</a>',
          equipmentHandlingInfo2: 'Når den nåværende eierens sluttdato er nådd, vil utstyret bli tilbakestilt og overført til den valgte ansatte. Vi tilbakestiller utstyret kl. 14:00 på sluttdato. Dersom du har spesifikke forespørsler rundt dette, ta kontakt med Bravo kundesupport på <a href="mailto:support{\'@\'}bravosteps.no">support{\'@\'}bravosteps.no</a>',
          equipmentHandlingInfo3: 'Dersom dere leaser utstyr av Bravo gjennom en DaaS-avtale, kan utstyret leveres inn til oss. Vi tar kontakt med dere ang innlevering av utstyret.',
          equipmentDate: 'Velg dato for tilbakestilling',
          unregisterSummary: 'Oppsummering',
          unregisterConfirmation: 'Bekreftelse',
          caseSubmitted: 'Forespørselen din er sendt inn til Bravo og det er opprettet en sak i help desk.',
          unregisterText: 'Bravo vil motta og behandle avregistreringen. Du vil motta en epost når det er håndtert. Etter sluttdato vil den ansatte ikke lenger ha tilgang til sin e-post, Teams eller OneDrive, og vil ikke lenger kunne logge inn på sin jobb-PC. Autosvar vil fungere tre uker etter sluttdato og deretter vil kontoen bli fullstendig terminert.',
          seeCase: 'Se forespørsel i help desk',
          infoAbout: 'Informasjon om',
          endDate: 'Sluttdato',
          equipmentHandled: 'Utstyr som håndteres',
          infoAboutLicense: 'Info om lisens',
          infoLicenseTerminated: 'Lisenser tilknyttet den ansatte sies opp av Bravo i henhold til avtale',
          formSubmitted: 'Henvendelse sendt',
          noBundle: 'Ingen lisenspakke-avtale funnet',
          orderContact: 'Vil du bestille en lisenspakke? Ta kontakt med din kundeansvarlige på <a href="mailto:{email}">{email}</a>',
          orderInfo: 'Vi tilbyr skreddersydde lisenspakker med drift, forvaltning, sikkerhet og IT-support – alt på ett sted.',
          orderMoreInfo: 'Les mer på <a href="https://www.bravosteps.no" target="_blank">www.bravosteps.no</a>',
          comment: 'Kommentar',
          commentExample: 'Feks jeg ønsker...'
        },

        feedback: {
          name: 'Tilbakemelding',
          give: 'Gi tilbakemelding',
          question: 'Hva synes du om brukeropplevelsen i Bravo portalen?',
          noGood: 'Ikke så bra',
          good: 'Veldig bra',
          getGood: 'Hva kan vi gjøre bedre?',
          placeholder: 'Vi ønsker ærlige og konstruktive tilbakemeldinger så vi kan gjøre brukeropplevelsen bedre for deg',
          email: 'Legg igjen epost om du vil snakke med oss',
          send: 'Send tilbakemelding',
          thanks: 'Vi setter pris på din tilbakemelding',
          outro: 'Din tilbakemelding brukes i videre utvikling av portalen, for å gjøre brukeropplevelsen best mulig for deg.'
        },

        files: {
          add: 'Vedlegg',
          drop: 'Last opp eller slipp filer her',
          dropHere: 'Slipp filene her',
          limit: 'jpeg eller png, maks 4 MB'
        },

        general: {
          yes: 'Ja',
          no: 'Nei',
          account: 'Konto',
          noAccount: 'Ugyldig bruker',
          login: 'Login',
          logout: 'Logg ut',
          details: 'Detaljer',
          category: 'Kategori',
          inventory: 'Inventar',
          expires: 'Utløper',
          expirationDate: 'Utløpsdato',
          delete: 'Slett',
          notImplemented: 'Ikke implementert',
          notImplementedIncoming: 'Funksjonen er ikke implementert, men kommer.',
          coming: 'Kommer!',
          newComming: 'Ny funksjonalitet på vei!',
          newCommingEquipment: 'Snart kan du velge og bestille utstyr direkte fra denne siden. Stay tuned!',
          goTo: 'Gå til',
          goBack: 'Gå tilbake',
          mandatory: 'Obligatorisk',
          notMandatory: 'Ikke obligatorisk',
          firstname: 'Fornavn',
          lastname: 'Etternavn',
          email: 'Epost',
          emailPrivate: 'Privat epost',
          mobile: 'Mobil',
          phone: 'Telefonnummer',
          location: 'Lokasjon',
          city: 'Sted',
          address: 'Adresse',
          department: 'Avdeling',
          nearestManager: 'Nærmeste leder',
          next: 'Neste',
          save: 'Lagre',
          search: 'Søk',
          name: 'Navn',
          job: 'Stilling',
          status: 'Status',
          noStatus: 'Ingen status',
          complianceStatus: 'Sikkerhetsstatus',
          compliant: 'Compliant',
          notCompliant: 'Not compliant',
          unknown: 'Ukjent',
          date: 'Dato',
          order: 'Ordre',
          sum: 'Sum',
          other: 'Annet',
          errorTryAgain: 'Noe gikk feil. Prøv igjen',
          title: 'Tittel',
          open: 'Åpen',
          //active: 'Aktiv',
          closed: 'Løst',
          cancelled: 'Kansellert',
          //solved: 'Lukket',
          topic: 'Emne',
          contact: 'Kontakt',
          floor: 'Etasje',
          bravoCustomerService: 'Bravo kundeservice',
          send: 'Send',
          readMore: 'Les mer',
          readLess: 'Les mindre',
          filter: 'Filter',
          reset: 'Nullstill',
          applyFilter: 'Bruk filter',
          sort: 'Sorter',
          language_en: 'English',
          language_no: 'Norsk',
          download: 'Last ned',
          employee: 'Ansatt',
          meetingRoom: 'Møterom',
          equipment: 'Utstyr',
          noHits: 'Ingen treff',
          all: 'Alle',
          domain: 'Domene',
          error_invalidFormat: 'Feil format',
          selected: 'Valgt',
          clearForm: 'Nullstill skjema'
        },

        incidentStatus: {
          inProgress: 'Under arbeid',
          onHold: 'På vent',
          respondedByCustomer: 'Under arbeid',
          waitingForCustomer: 'Ny melding',
          problemSolved: 'Løst',
          cancelled: 'Kansellert',
          informationProvided: 'Under arbeid',
          merged: 'Løst',
          waitingFor3rdParty: 'På vent',
          new: 'Ny sak',
          createdWorkOrder: 'Eskalert til tekniker',
          scheduled: 'Service bestilt'
        },

        incidentOrigin: {
          phone: 'Telefon',
          email: 'Epost',
          assignment: 'Assignment',
          portal: 'Portalmelding',
        }
      }
    }
  })
}